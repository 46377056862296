import { Link, MaxWidthContent } from 'components/Base';
import DefaultPage from 'layouts/DefaultPage';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import tw from 'twin.macro';

const Headline = tw.h1`text-center font-bold text-xl mx-auto mb-20 lg:(text-2xl mb-20)`;

const ErrorWrapper = tw.div`min-height[400px] height[50vh] mt-20 lg:mt-24 lg:height[75vh] flex flex-col items-center justify-center`;
const ButtonWrapper = tw.div``;

const Error: NextPage = () => {
  return (
    <>
      <NextSeo title="Pagina non trovata" />
      <DefaultPage>
        <MaxWidthContent>
          <ErrorWrapper>
            <Headline>Non abbiamo trovato la pagina che cercavi.</Headline>
            <ButtonWrapper>
              <Link href="/">Torna alla home page</Link>
            </ButtonWrapper>
          </ErrorWrapper>
        </MaxWidthContent>
      </DefaultPage>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Error;
